import React, { memo, useState, ReactNode } from 'react';
import BSAccordion from 'react-bootstrap/Accordion';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import styles from './Accordion.module.scss';
import SmoothChevronIcon from '../Icons/SmoothChevron';

export const ACCORDION_TEST_ID = 'accordion';
export const ACCORDION_BTN_TEST_ID = 'accordion-btn';

export type AccordionItem = {
  accordionItemClassname?: string;
  accordionHeaderClassname?: string;
  accordionBodyClassname?: string;
  eventKey: string;
  title: string;
  description?: string | ReactNode;
  imgSrc?: string;
  element?: JSX.Element | JSX.Element[];
  preHeaderIcon?: JSX.Element;
};

type AccordionProps = {
  id: string;
  className?: string;
  activeKey?: AccordionEventKey;
  defaultActiveKey?: AccordionEventKey;
  children: AccordionItem[];
  flush?: boolean;
  alwaysOpen?: boolean;
  smoothChevronIcon?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
};

const Accordion: React.FC<AccordionProps> = ({
  id,
  className = '',
  defaultActiveKey,
  children,
  flush = false,
  alwaysOpen = false,
  smoothChevronIcon = false,
  onClick,
}: AccordionProps) => {
  const [selectedKey, setSelectedKey] = useState<AccordionEventKey>(defaultActiveKey);

  const handleSelect = (key: AccordionEventKey): void => {
    setSelectedKey(key);
  };

  const handleCollapse = (e: React.MouseEvent<HTMLElement>): void => {
    if (onClick) onClick(e);
  };

  const renderBaseHeader = (title: string, description?: string | ReactNode, imgSrc?: string): ReactNode => {
    return (
      <>
        {imgSrc ? <img alt={title} src={imgSrc} /> : null}
        <div className="accordion-header-container">
          <div className={`${styles.accordionHeaderTitle} accordion-header-title`}>{title}</div>
          {description ? (
            <div className={`${styles.accordionHeaderDescription} accordion-header-description`}>{description}</div>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <BSAccordion
      id={id}
      data-testid={ACCORDION_TEST_ID}
      className={`${styles.accordion} ${className}`}
      defaultActiveKey={defaultActiveKey}
      flush={flush}
      alwaysOpen={alwaysOpen}
      onSelect={handleSelect}
    >
      {children &&
        children.length > 0 &&
        children.map(
          (
            {
              accordionItemClassname,
              accordionHeaderClassname,
              accordionBodyClassname,
              eventKey,
              title,
              description,
              imgSrc,
              element,
            }: AccordionItem,
            idx: number,
          ) => (
            <BSAccordion.Item
              key={eventKey}
              className={`${styles.accordionItem} ${accordionItemClassname ?? ''}`}
              eventKey={eventKey}
            >
              <BSAccordion.Header
                data-testid={`${ACCORDION_BTN_TEST_ID}-${idx}`}
                className={`${styles.accordionHeader} ${accordionHeaderClassname ?? ''}`}
                onClick={handleCollapse}
              >
                <>
                  {renderBaseHeader(title, description, imgSrc)}
                  {smoothChevronIcon ? (
                    <SmoothChevronIcon rotate={selectedKey === eventKey ? 90 : 180} />
                  ) : (
                    <FontAwesomeIcon icon={selectedKey === eventKey ? faChevronUp : faChevronDown} />
                  )}
                </>
              </BSAccordion.Header>

              <BSAccordion.Body
                data-testid={`${ACCORDION_TEST_ID}-collapse-${idx}`}
                className={`${styles.accordionBody} ${accordionBodyClassname ?? ''}`}
              >
                {element}
              </BSAccordion.Body>
            </BSAccordion.Item>
          ),
        )}
    </BSAccordion>
  );
};

export default memo(Accordion);
