import React, { FC, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/sharp-regular-svg-icons/faXmark';

const backdropIndexClassNames = {
  1050: 'backdrop-1050',
  1060: 'backdrop-1060',
  1070: 'backdrop-1070',
};

export const MODAL_TEST_ID = 'modal';

export type ModalSizeTypes = 'sm' | 'lg' | 'xl';

export interface ModalProps {
  className?: string;
  bodyClassName?: string;
  show: boolean;
  size?: ModalSizeTypes;
  backdrop?: 'static' | true | false;
  backdropClassName?: string;
  backdropIndex?: '1050' | '1060' | '1070';
  titleTag?: any;
  closeButton?: boolean;
  centered?: boolean;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  header?: JSX.Element | JSX.Element[];
  body?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  onHide?: () => void;
  enforceFocus?: boolean;
  id?: string;
  testId?: string;
  showHeader?: boolean;
  dialogClassName?: string;
  contentClassName?: string;
}

const BaseModal: FC<ModalProps> = ({
  className = '',
  bodyClassName = '',
  show,
  size = 'lg',
  backdrop = true,
  backdropIndex,
  backdropClassName = '',
  titleTag = 'h1',
  closeButton = true,
  centered = false,
  title,
  subtitle,
  header,
  body,
  footer,
  onHide = (): void => {},
  testId = MODAL_TEST_ID,
  enforceFocus = true,
  id = undefined,
  showHeader = true,
  dialogClassName = '',
  contentClassName = '',
}: ModalProps) => {
  return (
    <Modal
      data-testid={testId}
      className={className}
      backdropClassName={`${backdropClassName} ${backdropIndex ? backdropIndexClassNames[backdropIndex] : ''}`}
      backdrop={backdrop}
      show={show}
      size={size}
      centered={centered}
      onHide={onHide}
      enforceFocus={enforceFocus}
      id={id}
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
    >
      {showHeader ? (
        <Modal.Header data-testid={`${testId}-header`} closeButton={false}>
          {isEmpty(header) && (
            <div className="row g-0 no-gutters">
              <Modal.Title as={titleTag}>{title}</Modal.Title>
              <div className="modal-subtitle">{subtitle}</div>
            </div>
          )}
          {!isEmpty(header) && header}
          {closeButton && (
            <button type="button" className="btn-close" aria-label="Close" onClick={onHide}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </Modal.Header>
      ) : null}
      {body && <Modal.Body className={bodyClassName}>{body}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default memo(BaseModal);
