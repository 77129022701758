import React, { FC } from 'react';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import ProductDiscountBoxFoldIcon from '../../atoms/Icons/ProductDiscountBoxFold';
import Button from '../../atoms/Button';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import PromoChip from '../../atoms/PromoChip';
import { Deal } from '../../../types/deal';
import { Brand } from '../../../types/brands';
import { PromotionType } from '../../../types/promotion';
import { getIsLocalRebatePromotionFromSingleDeal, openDealOutboundUrl } from '../../../utils/promos';

export const BRAND_PROMO_BOX_ID = 'brand-promo-box';

export interface BrandPromoBoxProps {
  pageRegion: PageRegion;
  deal: Deal;
  onGetDeal?(deal: Deal): void;
}

const BrandPromoBox: FC<BrandPromoBoxProps> = ({ pageRegion, deal, onGetDeal }) => {
  const ctaData = {
    category: CTAClickCategory.CASHBACK_PROMOTION,
    level: CTAClickLevel.PRIMARY,
    url: '/',
    pageRegion,
    product: deal.brand ? getProductEventDataWithMattressHierarchy(deal.brand as Brand) : EMPTY_EVENT_PRODUCT,
  };

  return (
    <div data-testid={BRAND_PROMO_BOX_ID} className="brand-promo-box">
      <Button
        className="brand-promo-box__button"
        ariaLabel="Brand Discount"
        ctaData={ctaData}
        onClick={(): void => {
          if (onGetDeal) onGetDeal(deal);

          openDealOutboundUrl(deal);
        }}
      >
        <div className="fold-background" />

        <ProductDiscountBoxFoldIcon />

        {deal.brand ? <img src={deal.brand.logo} alt={`${deal.brand.name} logo`} /> : null}
        {deal.deals[0].main_promotion_type === PromotionType.AFFILIATE_REBATE ||
        getIsLocalRebatePromotionFromSingleDeal(deal) ? (
          <PromoChip label={`${deal.deals[0].description} Back`} />
        ) : null}
      </Button>
    </div>
  );
};

export default BrandPromoBox;
