import { COLLECTION, LINE, MANUFACTURER, MODEL } from '../constants/commons';
import { BrandExtraData, BrandRating, PrincipalMaterials, WarrantyInfo } from './brands';
import { Image, Price, ProductLayer, Size, Softness } from './commons';
import { NewPromotion } from './promotion';
import { Retailer } from './retailer';

export const MAX_COMPARE_ITEMS = 5;

export type CompareType = 'mod' | 'brand' | '';

export type CategoryContentRowType = {
  id: string;
  title: string | JSX.Element;
  tooltipTitle?: string;
  tooltip?: string | JSX.Element;
  wrappedTitle?: boolean;
};

export type CategoryContentType = {
  id: string;
  title: string;
  tooltip?: string;
  description?: string;
  wrappedDescription?: boolean;
  rows: CategoryContentRowType[];
};

export interface HeaderPopularComparison {
  header: string;
  title: string;
  url: string;
}

export interface MatchedPrice {
  matchedPrice: Price | undefined;
  id: number;
}

export interface CompareItem {
  slug: string;
  name: string;
  img: string;
  logo: string;
  classname?: string;
  type?: CompareType;
  isActive?: boolean;
  url?: string;
  externalDomain?: string;
}

type AvailableModel = {
  classname: string;
  classname_alias: string;
  slugs: string;
  absolute_url: string;
  reviews_url: string;
  name: string;
  name_short: string;
  name_unique: string;
  logo: string;
  goodbed_rating: number;
  brand_rating: {
    goodbed_rating: number;
    goodbed_rating_description: string;
    rating_count: number;
    review_count: number;
  };
};

type SoftnessLevel = {
  name: string;
  value: number;
};

export interface EditorialRating {
  id: number;
  object_name: string;
  object_id: string;
  brand_id: string;
  has_back_support: boolean;
  has_comfort_and_feel: boolean;
  has_durability_and_value: boolean;
  has_other_features: boolean;
  has_pressure_relief: boolean;
  ease_of_repositioning_full?: EditorialRatingObjectType;
  mattress_height?: number;
  motion_isolation_full?: EditorialRatingObjectType;
  spine_align_side_light_full?: EditorialRatingObjectType;
  spine_align_side_avg_full?: EditorialRatingObjectType;
  spine_align_side_above_avg_full?: EditorialRatingObjectType;
  spine_align_side_heavy_full?: EditorialRatingObjectType;
  spine_align_back_light_full?: EditorialRatingObjectType;
  spine_align_back_avg_full?: EditorialRatingObjectType;
  spine_align_back_above_avg_full?: EditorialRatingObjectType;
  spine_align_back_heavy_full?: EditorialRatingObjectType;
  spine_align_stomach_light_full?: EditorialRatingObjectType;
  spine_align_stomach_avg_full?: EditorialRatingObjectType;
  spine_align_stomach_above_avg_full?: EditorialRatingObjectType;
  spine_align_stomach_heavy_full?: EditorialRatingObjectType;
  press_relief_typ_curves_light_full?: EditorialRatingObjectType;
  press_relief_typ_curves_avg_full?: EditorialRatingObjectType;
  press_relief_typ_curves_above_avg_full?: EditorialRatingObjectType;
  press_relief_typ_curves_heavy_full?: EditorialRatingObjectType;
  press_relief_ext_curves_light_full?: EditorialRatingObjectType;
  press_relief_ext_curves_avg_full?: EditorialRatingObjectType;
  press_relief_ext_curves_above_avg_full?: EditorialRatingObjectType;
  press_relief_ext_curves_heavy_full?: EditorialRatingObjectType;
  product_durability_full?: EditorialRatingObjectType;
  overall_softness_full?: EditorialRatingObjectType;
  overall_cushioning_depth_full?: EditorialRatingObjectType;
  overall_memory_feel_full?: EditorialRatingObjectType;
  overall_bounce_full?: EditorialRatingObjectType;
  overall_cooling_full?: EditorialRatingObjectType;
  overall_edge_support_full?: EditorialRatingObjectType;
  overall_green_features_full?: EditorialRatingObjectType;
  overall_value_full?: EditorialRatingObjectType;
  overall_adjustable_base_compatibility_full?: EditorialRatingObjectType;
  product?: EditorialRatingProduct;
  softness: Softness | undefined;
}

type BaseEditorialRatingProductHierarchy = {
  slug: string;
  name: string;
};

type EditorialRatingProductHierarchy = {
  [MANUFACTURER]: BaseEditorialRatingProductHierarchy;
  [LINE]: BaseEditorialRatingProductHierarchy;
  [COLLECTION]: BaseEditorialRatingProductHierarchy;
  [MODEL]: BaseEditorialRatingProductHierarchy;
};

export type CompareItemType = {
  classname: string;
  slug?: string;
  shortName?: string;
};

export type EditorialRatingObjectType = {
  name: string;
  value: number;
};

export type LowestPrice = {
  bundle: string;
  external_domain: string;
  external_url: string;
  id: number;
  is_eligible: boolean;
  offer: string;
  outbound_url: string;
  price: number;
  size: Size;
};

export type EditorialRatingProductOffers = {
  lowest_price: LowestPrice;
};

export interface EditorialRatingProduct {
  id: number;
  absolute_url: string;
  available_models?: AvailableModel[];
  brand_outbound_url?: string;
  brand_rating: BrandRating;
  cashback: NewPromotion;
  classname: string;
  cover: string;
  cover_image: Image;
  durablity: EditorialRatingObjectType;
  insight: string;
  is_active: boolean;
  listed: boolean;
  external_domain: string;
  external_url: string;
  extra_data: BrandExtraData;
  logo: string;
  name: string;
  offers: EditorialRatingProductOffers[];
  outbound_url: string;
  prices: Price[];
  principal_materials: PrincipalMaterials[];
  retailer: Retailer;
  reviews_url: string;
  slug: string;
  softness_levels?: SoftnessLevel[];
  softness_type?: string;
  mattress_size: string;
  match_score: EditorialRatingObjectType;
  warranty: WarrantyInfo;
  hierarchy: EditorialRatingProductHierarchy;
  layers: ProductLayer[];
}

export type LayerRowHeight = {
  layerType: string;
  height: number;
};
