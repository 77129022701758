'use client';

import React, { ReactNode, memo, useRef } from 'react';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import cls from 'classnames';
import Link from 'next/link';
import { REVIEWS_LIST_ID } from '../../../utils/review-summary';
import { ROUTES, CLASSNAMES, MATTRESS, MANUFACTURER } from '../../../constants/commons';
import { Mattresses, ReviewsSummary } from '../../../types/review-summary';
import LinkButton from '../../atoms/LinkButton';
import CustomersRating from '../../molecules/Product/CustomerRating';
import { numberWithThousands } from '../../../utils/commons';
import SmoothChevronIcon from '../../atoms/Icons/SmoothChevron';
import useCollapse from '../../../hooks/useCollapse';
import CategoryRatings from './CategoryRatings';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { MIN_CATEGORY_RATINGS_NEEDED_TO_SHOW_SUMMARY, MIN_REVIEWS_COUNT } from '../../../constants/review-summary';
import { filterObject } from '../../../utils/collections';

export type MattressesSummaryProps = {
  product: Mattresses;
  summaryData?: ReviewsSummary;
  type?: 'profile-page';
};

export const REVIEW_SUMMARY_ID = 'review-summary';

const MattressesSummary: React.FunctionComponent<MattressesSummaryProps> = ({
  product,
  summaryData,
  type,
}: MattressesSummaryProps) => {
  const extraInfoContainerRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { classname, slug, brand_rating: brandRating } = product;

  const { toggleCollapse, isCollapsed } = useCollapse(extraInfoContainerRef, {
    initialCollapse: false,
  });
  const topObject = classname === MANUFACTURER ? product : product.manufacturer;
  const externalDomain = topObject?.external_domain;
  const outboundURL =
    product?.outbound_url && product.get_status_display === 'Active' ? product.outbound_url : topObject?.outbound_url;
  const showShopThisBedButton = topObject?.outbound_url && topObject?.get_status_display === 'Active';
  const reviewMattressUrl = `${ROUTES.baseMattressReviewsForm}${
    CLASSNAMES[classname as keyof typeof CLASSNAMES]
  }/${slug}`;

  const getWriteReviewLink = (): JSX.Element => (
    <div className="mattresses-summary__ctas__review-mattress">
      <Link href={reviewMattressUrl}>Write a review of this product</Link>
      <SmoothChevronIcon color="#4561A4" />
    </div>
  );

  const isProfilePage = type === 'profile-page';

  const hasEnoughReviews = product.rating_count >= MIN_REVIEWS_COUNT || product.review_count >= MIN_REVIEWS_COUNT;

  const getSecondaryLinkButton = (): ReactNode => {
    if (isProfilePage) {
      if (hasEnoughReviews)
        return (
          <LinkButton
            className="mattress-reviews-customer-reviews-btn"
            to={product.reviews_url}
            ariaLabel="See All Customer Reviews"
            variant="quiz-outline"
            size="lg"
            useRouter={false}
            ctaData={{
              category: CTAClickCategory.SEE_MORE_REVIEWS,
              level: CTAClickLevel.PRIMARY,
              url: ROUTES.mattressReviewsList,
              pageRegion: PageRegion.REVIEWS,
            }}
          >
            <p>Read All</p>
            <b>Customer Reviews</b>
          </LinkButton>
        );
    } else {
      return (
        <LinkButton
          className="mattress-reviews-quiz-btn"
          to={ROUTES.mattressFinderQuiz}
          ariaLabel="Take the GoodBed Match Quiz"
          variant="quiz-outline"
          size="lg"
          ctaData={{
            category: CTAClickCategory.TAKE_THE_QUIZ,
            level: CTAClickLevel.PRIMARY,
            url: ROUTES.mattressFinderQuiz,
            pageRegion: PageRegion.REVIEWS_LIST,
          }}
        >
          <p>Take the</p>
          <b>GoodBed Match Quiz</b>
        </LinkButton>
      );
    }

    return null;
  };

  const filteredCategoryRatings = filterObject(summaryData || {}, (key, value) => {
    // Comfort Category Rating is not used in this component.
    if (key === 'comfort') return false;
    return !!value.value;
  });

  const notShowCategoryRatings =
    Object.keys(filteredCategoryRatings).length < MIN_CATEGORY_RATINGS_NEEDED_TO_SHOW_SUMMARY && isProfilePage;

  const getExtraDataSection = (isDesktop: boolean): JSX.Element => (
    <>
      <div className={cls('mattresses-summary__ratings', { desktop: isDesktop })}>
        {notShowCategoryRatings ? (
          <p className="mattresses-summary__ratings__no-data">
            We’re still working on collecting customer reviews for this product, but don’t yet have enough to provide a
            summary of customer feedback.
            {product?.parent?.reviews_url && product?.parent.name ? (
              <>
                {' '}
                In the meantime, we recommend looking at{' '}
                <a href={product?.parent?.reviews_url} style={{ fontWeight: 'bold' }}>
                  reviews for all {product?.parent.name} products.
                </a>
              </>
            ) : null}
          </p>
        ) : (
          <CategoryRatings summaryData={summaryData} type="review-list-summary" isMobile />
        )}
      </div>

      <div
        className={cls('mattresses-summary__ctas', {
          desktop: isDesktop,
          'not-enough-reviews': !hasEnoughReviews,
        })}
      >
        {isMobile ? getWriteReviewLink() : null}

        {showShopThisBedButton ? (
          <div
            className={cls('mattress-reviews-shop-btn-container', { 'only-btn': !hasEnoughReviews && isProfilePage })}
          >
            <LinkButton
              className="mattress-reviews-shop-btn"
              to={outboundURL || ''}
              ariaLabel="Shop this Bed"
              variant="light-green"
              target="_blank"
              size="lg"
              ctaData={{
                category: CTAClickCategory.SHOP_FOR_BEDS,
                level: CTAClickLevel.PRIMARY,
                url: outboundURL,
                pageRegion: PageRegion.REVIEWS_LIST,
              }}
              useRouter={false}
            >
              <b>{classname === MATTRESS && product.outbound_url ? 'Shop this Bed' : 'Shop this Brand'}</b>
              <p>on {externalDomain}</p>
            </LinkButton>
          </div>
        ) : null}

        {!isProfilePage ? (
          <p className="mattresses-summary__ctas__quiz-text">Not sure this product is right for you?</p>
        ) : null}

        {getSecondaryLinkButton()}

        {!isMobile ? getWriteReviewLink() : null}
      </div>
    </>
  );

  return (
    <div
      className={cls('mattresses-summary', isMobile ? 'mb-3' : 'mb-4', {
        'profile-page': isProfilePage,
        'no-reviews': !hasEnoughReviews,
        'no-category-ratings': notShowCategoryRatings,
      })}
    >
      <div
        role="button"
        aria-label=""
        tabIndex={0}
        className={`mattresses-summary__main-info ${isCollapsed ? 'info-collapsed' : ''}`}
        onClick={
          !isProfilePage
            ? (): void => {
                toggleCollapse();
              }
            : undefined
        }
      >
        <CustomersRating
          slug={slug}
          type={classname}
          ratingLinkURL={`#${REVIEWS_LIST_ID}`}
          ratingLinkTarget="_self"
          customersRating={brandRating.goodbed_rating}
          ratingDisplayText={brandRating.goodbed_rating_display}
          description={brandRating.goodbed_rating_description}
          ratingCount={brandRating.rating_count}
          shouldUseRatingsLink={false}
          subDescription={`Based on ${numberWithThousands(
            brandRating.rating_count || 0,
            5,
          )} real owner experiences with this ${classname === MATTRESS ? 'mattress' : 'brand'}.`}
        />

        {isMobile && !isProfilePage ? (
          <div className="mattresses-summary__main-info__collapse-icon">
            <SmoothChevronIcon color="#333" className={cls({ 'is-collapsed': isCollapsed })} />
          </div>
        ) : null}
      </div>

      <div className="mattresses-summary__mobile-container" ref={!isProfilePage ? extraInfoContainerRef : undefined}>
        {getExtraDataSection(false)}
      </div>

      {getExtraDataSection(true)}
    </div>
  );
};

export default memo(MattressesSummary);
